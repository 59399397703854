// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "rawline", sans-serif;

  // colors
  --color-white: #fff;
  --color-red: #fc4040;
  --color-black: #000000;
  --color-grey: #bebebe;
}
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  mdm: 840px,
  lg: 960px,
  xl: 1280px,
  xxl: 1440px,
);

$container-max-widths: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 960px,
  xl: 1280px,
  xxl: 1440px,
);
$gutter: 60px;
$gutters: (
  0: 0,
  20: 20px,
  30: 30px,
  40: 40px,
  45: 45px,
  47: 47px,
  48: 48px,
  49: 49px,
  50: 50px,
  55: 55px,
  57: 57px,
  60: 60px,
);

$spacers: (
  0: 0,
  3: 3px,
  5: 5px,
  10: 10px,
  12: 12px,
  14: 14px,
  15: 15px,
  17: 17px,
  20: 20px,
  21: 21px,
  22: 22px,
  23: 23px,
  24: 24px,
  25: 25px,
  27: 27px,
  28: 28px,
  29: 29px,
  30: 30px,
  40: 40px,
  45: 45px,
  47: 47px,
  48: 48px,
  49: 49px,
  50: 50px,
  52: 52px,
  55: 55px,
  57: 57px,
  60: 60px,
  70: 70px,

  80: 80px,
  90: 90px,
  100: 100px,
  105: 105px,
  108: 108px,
  110: 110px,
  115: 115px,
  120: 120px,
  130: 130px,
  160: 160px,
  200: 200px,
  240: 240px,
);

// @for $i from 0 through 240 {
//   $gutters: map-merge(
//     $gutters,
//     (
//       $i: #{$i}px,
//     )
//   );
//   $spacers: map-merge(
//     $spacers,
//     (
//       $i: #{$i}px,
//     )
//   );
// }
